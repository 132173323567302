import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import React from "react";
import Transactions from "utils/Transactions";
import moment from "moment";

const DateCell = ({ transaction }) => {
  const { timestamp, description } = transaction;

  const { uiTexts } = useSelector(getTextsData);

  const advancedType = Transactions.getTransactionAdvancedType(transaction);

  const advancedTypeText = uiTexts[advancedType] || uiTexts.other;

  const timestampFormated = timestamp
    ? moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)
    : Constants.EMPTY_PLACEHOLDER;

  return (
    <div className={Css.dateCell}>
      <div className={Css.timestamp} title={timestampFormated}>
        {timestampFormated}
      </div>
      <Badge
        data-type={advancedType}
        title={advancedTypeText}
        value={advancedTypeText}
        className={Css.badge} />
      <AutoTooltip className={Css.description}>
        {description
          ? (<Icons.Info />)
          : (<Icons.Warning className={Css.warning} />)}
        <span>{description || `${uiTexts.noBankDescriptionOrMemo} :(`}</span>
      </AutoTooltip>
    </div>
  );
};

export default React.memo(DateCell);
