import Css from "./style.module.scss";

import {
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessLocations
} from "selectors/businesses";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";
import SelectClassInput from "nlib/common/SelectClassInput";
import SelectLocationInput from "nlib/common/SelectLocationInput";

const ClassAndLocationCell = (props) => {
  const {
    disabled,
    transactionState,
    onChange
  } = props;

  const { class: classValue = {}, location = {} } = transactionState;

  const { id: classId, name: className } = classValue;

  const { id: locationId, name: locationName } = location;

  const {
    settings: {
      allowClientClassSelection,
      allowClientLocationSelection
    } = {}
  } = useSelector(getSelectedBusinessData);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const handleClassChange = useCallback((newValue) => {
    onChange({ class: newValue });
  }, [onChange]);

  const handleLocationChange = useCallback((newValue) => {
    onChange({ location: newValue });
  }, [onChange]);

  return (
    <div className={Css.categoryAndTaxRateCell}>
      {allowClientClassSelection && !!classes.length && (
        <SelectClassInput
          useTooltip
          size="sm"
          className={Css.input}
          disabled={disabled}
          invalid={!!(className && !classId)}
          valid={!!(className && classId)}
          value={classValue}
          onChange={handleClassChange} />
      )}
      {allowClientLocationSelection && !!locations.length && (
        <SelectLocationInput
          useTooltip
          size="sm"
          className={Css.input}
          disabled={disabled}
          invalid={!!(locationName && !locationId)}
          valid={!!(locationName && locationId)}
          value={location}
          onChange={handleLocationChange} />
      )}
    </div>
  );
};

export default React.memo(ClassAndLocationCell);
