import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import React from "react";
import TransactionAmount from "nlib/common/TransactionAmount";
import Utils from "utils/Utils";
import classNames from "classnames";

const TransactionCell = ({ transaction }) => {
  const { account, type, currency, amount, amountTotal = null } = transaction;

  const { uiTexts } = useSelector(getTextsData);

  return (
    <div className={Css.transactionCell}>
      <AutoTooltip active={!!account.name}>
        <TransactionAmount
          className={Css.amount}
          amount={amount}
          type={type}
          currency={currency} />
      </AutoTooltip>
      {account && (
        <div className={Css.account}>
          <AutoTooltip active={!!account.name}>
            <span>{account.name}</span>
          </AutoTooltip>
        </div>
      )}
      {amountTotal !== null && (
        <AutoTooltip className={classNames(Css.description)}>
          {`${uiTexts.total} ${Utils.toMoneyString(amountTotal, currency)}`}
        </AutoTooltip>
      )}
    </div>
  );
};

export default React.memo(TransactionCell);
