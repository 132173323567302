import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getAccountsData } from "selectors/accounts";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import DataConstants from "const/DataConstants";
import DateRangePickerExtended from "mlib/common/DateRangePickerExtended";
import DebounceInput from "nlib/ui/DebounceInput";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "nlib/ui/Select";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";
import usePageData from "hooks/usePageData";

const Filters = () => {
  const [envVars, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const accountsData = useSelector(getAccountsData);

  const hasFilters = !![envVars.fromDate, envVars.toDate, envVars.accountId, envVars.type].find(Boolean);

  const {
    text,
    fromDate,
    toDate,
    accountId,
    type
  } = envVars;

  const [textInputValue, setTextInputValue] = useState(text);

  const [opened, setOpened] = useState(false);

  const [searchFocused, setSearchFocused] = useState(false);

  const fromToDate = useMemo(() => [fromDate, toDate], [fromDate, toDate]);

  const { titleLangId } = usePageData();

  const filtersActive = [text, fromDate, toDate, accountId, type].find(Boolean);

  const accountOptions = useMemo(() => [
    accountId && { label: uiTexts.all },
    ...accountsData
      .filter(({ extraData: { paymentAccount } = {} }) => !quickBooksBusiness || paymentAccount)
      .map(({ id, name, accountNumber }) => ({
        value: id,
        label: name || accountNumber
      }))
  ], [accountId, accountsData, quickBooksBusiness, uiTexts.all]);

  const typeOptions = useMemo(() => [
    type && { label: uiTexts.all },
    { value: DataConstants.TRANSACTION_TYPES.WITHDRAW, label: uiTexts.expenses },
    { value: DataConstants.TRANSACTION_TYPES.DEPOSIT, label: uiTexts.income }
  ], [type, uiTexts]);

  const renderAccountLabel = useCallback((label) => {
    return (
      <div className={Css.selectToggleButton}>
        <Icons.CreditCard />
        <span className={Css.title}>
          {uiTexts.account}
        </span>
        <span
          className={classNames(Css.label, label && Css.hasSelected)}>
          {label || uiTexts.selectAccount}
        </span>
      </div>
    );
  }, [uiTexts]);

  const renderTypeLabel = useCallback((label) => {
    return (
      <div className={Css.selectToggleButton}>
        <Icons.CurrencyCircleDollar />
        <span className={Css.title}>
          {uiTexts.type}
        </span>
        <span
          className={classNames(Css.label, label && Css.hasSelected)}>
          {label || uiTexts.selectType}
        </span>
      </div>
    );
  }, [uiTexts]);

  const handleTextInputComplete = useCallback((value) => {
    setEnvVars({ text: value });
  }, [setEnvVars]);

  const handleDateChange = useCallback((value) => {
    const [first, second] = value;

    setEnvVars({ fromDate: first, toDate: second });
  }, [setEnvVars]);

  const handleAccountChange = useCallback((value) => {
    setEnvVars({ accountId: value });
  }, [setEnvVars]);

  const handleTypeChange = useCallback((value) => {
    setEnvVars({ type: value });
  }, [setEnvVars]);

  const handleResetClick = useCallback(() => {
    setEnvVars({ text: null, fromDate: null, toDate: null, accountId: null, type: null });
  }, [setEnvVars]);

  const handleDropDownToggle = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  const handleSearchFocus = useCallback(() => {
    setSearchFocused(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    setSearchFocused(false);
  }, []);

  useEffect(() => {
    setTextInputValue(text);
  }, [text]);

  return (
    <>
      <div className={classNames(Css.filters, hasFilters && Css.active)}>
        <div className={classNames(Css.container, CommonCss.mobileContainer)}>
          <div className={Css.title}>
            <span>{uiTexts[titleLangId]}</span>
            <DebounceInput
              cleanable
              className={classNames(Css.search, (!!textInputValue || searchFocused) && Css.focused)}
              active={!!envVars.text}
              placeholder={uiTexts.searchTransactions}
              iconBefore={Icons.MagnifyingGlass}
              value={textInputValue}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              onChange={setTextInputValue}
              onInputComplete={handleTextInputComplete} />
          </div>
          <Button
            outline
            primary={hasFilters}
            className={Css.moreFilters}
            onClick={handleDropDownToggle}>
            <Icons.Funnel />
          </Button>
        </div>
      </div>
      {opened && (
        <Modal
          title={uiTexts.filters}
          className={Css.filtersModal}
          onClose={handleDropDownToggle}>
          <div className={Css.list}>
            <div className={Css.listItem}>
              <DateRangePickerExtended
                className={Css.datePicker}
                value={fromToDate}
                placeholder={uiTexts.selectDate}
                label={uiTexts.dateRange}
                onChange={handleDateChange} />
            </div>
            <div className={Css.listItem}>
              <Select
                modal
                active={!!accountId}
                className={Css.select}
                value={accountId}
                title={uiTexts.selectAccount}
                options={accountOptions}
                renderLabel={renderAccountLabel}
                onChange={handleAccountChange} />
            </div>
            <div className={Css.listItem}>
              <Select
                modal
                active={!!type}
                className={Css.select}
                value={type}
                title={uiTexts.selectType}
                options={typeOptions}
                renderLabel={renderTypeLabel}
                onChange={handleTypeChange} />
            </div>
          </div>
          <div className={Css.buttons}>
            <Button
              primary outline
              disabled={!filtersActive}
              className={Css.button}
              onClick={handleResetClick}>
              <Icons.X /> {uiTexts.resetAll}
            </Button>
            <Button
              light
              className={Css.button}
              onClick={handleDropDownToggle}>
              {uiTexts.close}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default React.memo(Filters);
