import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { checkSelectedBusinessRpaMode, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Card from "nlib/common/Card";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import Popup from "nlib/ui/Popup";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { QUICK_BOOKS } = IntegrationServices;

const {
  STATUSES: {
    TO_REVIEW,
    TO_REPORT,
    IN_PROGRESS
  }
} = DataConstants;

const CLASS_NAMES = {
  [TO_REVIEW]: Css.toReview,
  [TO_REPORT]: Css.toReport,
  [IN_PROGRESS]: Css.inProgress
};

const ICONS = {
  [TO_REVIEW]: Icons.Eye,
  [TO_REPORT]: Icons.CheckCircle,
  [IN_PROGRESS]: Icons.Cpu
};

const TransactionsPopup = ({ transactionsForPopup, popupMode, disabled, className, onSubmit }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const {
    extraData: {
      integrationService,
      lastMatchingRpaAt,
      lastReconciliationRpaAt,
      transactionsLastSyncedAt,
      lastManualRpaWorkflowStartedAt
    } = {}
  } = useSelector(getSelectedBusinessData);

  const [shown, setShown] = useState(!!transactionsForPopup.length);

  const serviceName = Utils.capitalizeText(integrationService);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const businessUser = useSelector(checkIsBusinessUser);

  const Icon = ICONS[popupMode];

  const text = useMemo(() => {
    switch (popupMode) {
      case TO_REVIEW:
        return rpaMode && !businessUser ? messages.transactionsRpaReadyToReview : messages.transactionsReadyToReview;
      case TO_REPORT:
        return messages.transactionsReadyToApprove;
      case IN_PROGRESS:
        return integrationService === QUICK_BOOKS.value
          ? uiTexts.matchCountTransactionsInService : uiTexts.reconcileCountTransactionsInService;
      default:
        return "";
    }
  }, [popupMode, businessUser, rpaMode, integrationService, uiTexts, messages]);

  const buttonText = useMemo(() => {
    switch (popupMode) {
      case TO_REVIEW:
        return rpaMode && !businessUser ? uiTexts.processAll : uiTexts.moveAllToReview;
      case TO_REPORT:
        return uiTexts.approveAll;
      case IN_PROGRESS:
        return integrationService === QUICK_BOOKS.value
          ? uiTexts.matchTransactions : uiTexts.reconcileTransactions;
      default:
        return "";
    }
  }, [popupMode, businessUser, rpaMode, integrationService, uiTexts]);

  const handleButtonClick = useCallback(() => {
    setShown(false);
    onSubmit();
  }, [onSubmit]);

  const lastRpaDoneAt = lastMatchingRpaAt || lastReconciliationRpaAt;

  const manualRpaCoolDown = (popupMode === IN_PROGRESS) && lastManualRpaWorkflowStartedAt
    && (
      !lastRpaDoneAt
      || moment(lastRpaDoneAt).isSameOrBefore(moment(lastManualRpaWorkflowStartedAt))
      || moment(transactionsLastSyncedAt).isSameOrBefore(moment(lastRpaDoneAt)));

  const hash = transactionsForPopup.map(({ id }) => id).join();

  useEffect(() => {
    setShown(!!hash);
  }, [hash]);

  return (
    <Popup
      shown={shown}
      className={classNames(
        Css.transactionsPopup,
        CLASS_NAMES[popupMode],
        className
      )}>
      <Card className={Css.card}>
        <span className={Css.icon}>
          {Icon ? <Icon weight="bold" /> : null}
        </span>
        <span className={Css.text}>
          {manualRpaCoolDown
            ? messages.manualRpaPopupCoolDown
            : Utils.replaceTextVars(text, {
              transactionsCount: transactionsForPopup.length,
              service: serviceName
            })}
        </span>
        <div className={Css.right}>
          {manualRpaCoolDown
            ? <Icons.CircleNotch data-wait />
            : (
              <Button
                outline
                disabled={disabled}
                icon={popupMode === IN_PROGRESS ? (
                  manualRpaCoolDown ? Icons.Clock : Icons.Check
                ) : Icons.PaperPlaneTilt}
                onClick={handleButtonClick}>
                {!Utils.checkIsTouchDevice() && Utils.replaceTextVars(buttonText, { service: serviceName })}
              </Button>
            )}
        </div>
      </Card>
    </Popup>
  );
};

export default React.memo(TransactionsPopup);
