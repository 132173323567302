import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AttachedDocumentActions from "nlib/common/AttachedDocumentActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentPreview from "nlib/common/DocumentPreview";
import DragAndDropArea from "nlib/ui/DragAndDropArea";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useState } from "react";
import TransactionsActions from "actions/TransactionsActions";
import useAttachDocument from "hooks/useAttachDocument";
import useEnvVars from "hooks/useEnvVars";

const ACCEPT_FILE_TYPES = Object
  .values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const {
  DOCUMENT_RECOGNISE_STATUSES,
  STATUSES: { TO_REVIEW },
  ADVANCED_TRANSACTION_TYPES: { TRANSFER }
} = DataConstants;

const DocumentCell = (props) => {
  const dispatch = useDispatch();

  const [, setEnvVars] = useEnvVars();

  const { vendorId, advancedType, transaction, refetchTableData } = props;

  const {
    id: transactionId,
    type,
    status,
    usesItems,
    documentId,
    documentPreview,
    documentRecogniseStatus
  } = transaction;

  const { uiTexts } = useSelector(getTextsData);

  const attachDocument = useAttachDocument({ id: transactionId, documentId, type, vendorId, status });

  const [loading, setLoading] = useState(false);

  const disabledInput = status === TO_REVIEW || advancedType === TRANSFER;

  const handleDropFiles = useCallback(async([file]) => {
    if (!file) return;
    setLoading(true);
    dispatch(TransactionsActions.lockTransaction(transactionId));

    const result = await attachDocument(file);

    if (result) refetchTableData([], true);

    setLoading(false);
    dispatch(TransactionsActions.unlockTransaction(transactionId));
  }, [attachDocument, dispatch, refetchTableData, transactionId]);

  const handlePreviewClick = useCallback(() => {
    setEnvVars({ editItem: transactionId, editTask: null });
  }, [setEnvVars, transactionId]);

  if (usesItems) {
    return (
      <div className={Css.documentCell}>
        {Constants.EMPTY_PLACEHOLDER}
      </div>
    );
  }

  return (
    <div className={Css.documentCell}>
      {documentId
        ? (
          <DocumentPreview
            className={Css.documentPreview}
            error={documentRecogniseStatus === DOCUMENT_RECOGNISE_STATUSES.ERROR}
            transactionId={transactionId}
            documentPreview={documentPreview}
            onClick={handlePreviewClick}>
            <AttachedDocumentActions
              compact
              className={Css.attachedDocumentActions}
              disabled={loading}
              canDownload={!!documentPreview}
              transaction={transaction} />
          </DocumentPreview>
        )
        : (
          <DragAndDropArea
            multiple={false}
            disabled={loading || disabledInput}
            title={(!disabledInput && !documentId) ? uiTexts.addDocument : ""}
            className={Css.attachment}
            accept={ACCEPT_FILE_TYPES}
            onDrop={handleDropFiles}>
            {({ dragOver, accepted }) => {
              if (loading) {
                return (<Preloader className={Css.preloader} />);
              }

              if (dragOver) {
                return (<Icons.UploadSimple />);
              }

              if (accepted.length) {
                return (
                  <div className={Css.extensions}>
                    {accepted[0].name.split(".").slice(-1)}
                  </div>
                );
              }

              return (<Icons.FileArrowUp />);
            }}
          </DragAndDropArea>
        )}
    </div>
  );
};

export default React.memo(DocumentCell);
