import Css from "./style.module.scss";

import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessData, getSelectedBusinessTaxRates } from "selectors/businesses";
import { useSelector } from "react-redux";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import React, { useCallback } from "react";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";

const { ADVANCED_TRANSACTION_TYPES: { TRANSFER } } = DataConstants;

const CategoryAndTaxRateCell = (props) => {
  const {
    advancedType,
    transaction,
    transactionState,
    disabledCategoryInput,
    onChange
  } = props;

  const { usesItems, type } = transaction;

  const { category = {}, taxRate = {} } = transactionState;

  const { countryCode } = useSelector(getActiveOrganization);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const usCountry = countryCode === Countries.US;

  const {
    settings: { allowClientCategorySelection, allowClientTaxRateSelection } = {}
  } = useSelector(getSelectedBusinessData);

  const assetAccountsOnly = advancedType === TRANSFER;

  const handleCategoryChange = useCallback((value) => {
    onChange({ category: value, tags: value.code ? [value.name] : [] });
  }, [onChange]);

  const handleTaxRateChange = useCallback((value) => {
    onChange({ taxRate: value });
  }, [onChange]);

  return (
    <div className={Css.categoryAndTaxRateCell}>
      {!usesItems && allowClientCategorySelection && (
        <SelectCategoryInput
          size="sm"
          className={Css.input}
          disabled={disabledCategoryInput}
          entityPaymentType={type}
          value={category}
          valid={!!category.code}
          invalid={!!category.name && !category.code}
          assetAccountsOnly={assetAccountsOnly}
          onChange={handleCategoryChange} />
      )}
      {allowClientTaxRateSelection && !usCountry && !!taxRates.length && (
        <SelectTaxRateInput
          useTooltip
          size="sm"
          className={Css.input}
          disabled={disabledCategoryInput}
          value={taxRate}
          valid={!!taxRate.id}
          invalid={!!taxRate.name && !taxRate.id}
          onChange={handleTaxRateChange} />
      )}
    </div>
  );
};

export default React.memo(CategoryAndTaxRateCell);
