import CommonCss from "nlib/common/common.module.scss";

import Css from "../../style.module.scss";

import { Checkbox } from "nlib/ui";
import { TableCell, TableRow } from "nlib/ui/Table";
import { getLockedTransactions } from "selectors/transactions";
import { getSelectedBusinessData } from "selectors/businesses";
import { useSelector } from "react-redux";
import ActionsCell from "../ActionsCell";
import CategoryAndTaxRateCell from "../CategoryAndTaxRateCell";
import ClassAndLocationCell from "../ClassAndLocationCell";
import CommentCell from "../CommentCell";
import DataConstants from "const/DataConstants";
import DateCell from "../DateCell";
import DocumentCell from "../DocumentCell";
import PayeeAndProjectCell from "../PayeeAndProjectCell";
import React, { useCallback, useMemo } from "react";
import TransactionCell from "../TransactionCell";
import Transactions from "utils/Transactions";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const {
  STATUSES: { TO_REVIEW },
  ADVANCED_TRANSACTION_TYPES: { BILL_PAYMENT, RECEIVED_PAYMENT, TRANSFER }
} = DataConstants;

const TransactionsTableRow = (props) => {
  const {
    disabled,
    selected,
    readyToReview,
    showPayeeAndProjectsCell,
    showCategoryAndTaxRateCell,
    showClassAndLocationCell,
    showBulkActions,
    showExplanationCell,
    showActionsCell,
    localReasons,
    transaction,
    transactionState,
    editableTransactionsIds,
    refetchTransactions,
    onChange,
    onReasonBlur: handleReasonBlur,
    onSelectedChange
  } = props;

  const [{ editItem }] = useEnvVars();

  const { settings: { advancedDocumentsWorkflow } = {} } = useSelector(getSelectedBusinessData);

  const lockedTransactions = useSelector(getLockedTransactions);

  const [editItemId] = editItem ? editItem.split(".") : [];

  const { id: transactionId, type, status, extraData, documentId } = transaction;

  const transactionLocked = !!lockedTransactions[transactionId];

  const seeTheDocument = !!documentId && advancedDocumentsWorkflow;

  const advancedType = useMemo(() => Transactions.getTransactionAdvancedType({ type, extraData }), [type, extraData]);

  const disabledContactInput = status === TO_REVIEW || advancedType === TRANSFER;

  const disabledCategoryInput = seeTheDocument
    || status === TO_REVIEW
    || advancedType === BILL_PAYMENT || advancedType === RECEIVED_PAYMENT;

  const disabledExtraDataInput = seeTheDocument
    || advancedType === TRANSFER
    || status === TO_REVIEW;

  const handleChange = useCallback((data) => {
    onChange(transactionId, data);
  }, [onChange, transactionId]);

  const handleSelectedChange = useCallback((value) => {
    onSelectedChange(transactionId, value);
  }, [onSelectedChange, transactionId]);

  return (
    <TableRow
      key={transaction.id}
      disabled={disabled || transactionLocked}
      className={classNames(
        CommonCss.tableRow,
        readyToReview && CommonCss.highlightRow,
        (transaction.id === editItemId || selected) && CommonCss.selectedRow
      )}>
      {showBulkActions && (
        <TableCell className={Css.checkboxCell}>
          <Checkbox
            disabled={!editableTransactionsIds.includes(transaction.id)}
            checked={selected}
            onChange={handleSelectedChange} />
        </TableCell>
      )}
      <TableCell className={Css.transactionCell}>
        <TransactionCell transaction={transaction} />
      </TableCell>
      <TableCell className={Css.dateCell}>
        <DateCell transaction={transaction} />
      </TableCell>
      <TableCell className={Css.commentCell} show={showExplanationCell}>
        <CommentCell
          disabled={disabled}
          transaction={transaction}
          transactionState={transactionState}
          localReasons={localReasons}
          onChange={handleChange}
          onReasonBlur={handleReasonBlur} />
      </TableCell>
      <TableCell
        className={Css.payeeAndProjectCell}
        show={showPayeeAndProjectsCell}>
        <PayeeAndProjectCell
          transaction={transaction}
          transactionState={transactionState}
          disabledContactInput={disabledContactInput}
          disabledProjectInput={disabledExtraDataInput}
          onChange={handleChange} />
      </TableCell>
      <TableCell
        className={Css.categoryAndTaxRateCell}
        show={showCategoryAndTaxRateCell}>
        <CategoryAndTaxRateCell
          advancedType={advancedType}
          transaction={transaction}
          transactionState={transactionState}
          disabledCategoryInput={disabledCategoryInput}
          disabledTaxRateInput={disabledExtraDataInput}
          onChange={handleChange} />
      </TableCell>
      <TableCell
        className={Css.classAndLocationCell}
        show={showClassAndLocationCell}>
        <ClassAndLocationCell
          disabled={disabledExtraDataInput}
          transactionState={transactionState}
          onChange={handleChange} />
      </TableCell>
      <TableCell className={Css.documentCell}>
        <DocumentCell
          vendorId={transactionState.vendorId}
          advancedType={advancedType}
          transaction={transaction}
          refetchTableData={refetchTransactions} />
      </TableCell>
      <TableCell className={Css.actionsCell} show={showActionsCell}>
        <ActionsCell
          disabled={disabled}
          readyToReview={readyToReview}
          transaction={transaction}
          transactionState={transactionState}
          refetchTableData={refetchTransactions} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(TransactionsTableRow);
