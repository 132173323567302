import Css from "./style.module.scss";

import {
  getCurrentZohoOrganizationId,
  getSelectedBusinessData,
  getSelectedBusinessProjects
} from "selectors/businesses";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useCallback } from "react";
import SelectContactInput from "nlib/common/SelectContactInput";
import SelectProjectInput from "nlib/common/SelectProjectInput";

const {
  TRANSACTION_TYPES: { WITHDRAW }
} = DataConstants;

const PayeeAndProjectCell = (props) => {
  const {
    transaction,
    transactionState,
    disabledContactInput,
    disabledProjectInput,
    onChange
  } = props;

  const { type, usesItems } = transaction;

  const { vendorId, address = {}, project = {} } = transactionState;

  const { id: projectId, name: projectName } = project;

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const projects = useSelector(getSelectedBusinessProjects);

  const {
    settings: {
      allowClientPayeeSelection,
      allowClientProjectSelection
    } = {}
  } = useSelector(getSelectedBusinessData);

  const withdrawTransaction = type === WITHDRAW;

  const handleChange = useCallback((value) => {
    onChange({ address: value, vendorId: value.id });
  }, [onChange]);

  const handleProjectChange = useCallback((newValue) => {
    onChange({ project: newValue });
  }, [onChange]);

  return (
    <div className={Css.payeeAndProjectCell}>
      {allowClientPayeeSelection && (
        <SelectContactInput
          useWidget
          useTooltip
          size="sm"
          onlyVendors={withdrawTransaction && zohoBusiness}
          onlyCustomers={!!usesItems || (!withdrawTransaction && zohoBusiness)}
          probablyVendor={withdrawTransaction && !usesItems}
          value={address}
          disabled={disabledContactInput}
          valid={!!(address.name && vendorId)}
          active={!!(address.name && !vendorId)}
          className={Css.input}
          onChange={handleChange} />
      )}
      {allowClientProjectSelection && !!projects.length && (
        <SelectProjectInput
          useTooltip
          size="sm"
          disabled={disabledProjectInput}
          invalid={!!(projectName && !projectId)}
          valid={!!(projectName && projectId)}
          value={project}
          className={Css.input}
          onChange={handleProjectChange} />
      )}
    </div>
  );
};

export default React.memo(PayeeAndProjectCell);
