import Css from "./style.module.scss";

import { getAllUsersData } from "selectors/organizations";
import { getOpenedCommentsEntityId } from "selectors/ui";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AddCommentField from "nlib/common/AddCommentField";
import AutoTooltip from "nlib/ui/AutoTooltip";
import CommentsBlock from "nlib/common/CommentsBlock";
import DataConstants from "const/DataConstants";
import DropDown from "nlib/ui/DropDown";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import React, { useCallback } from "react";
import ReasonInput from "nlib/common/ReasonInput";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

const {
  COMMENT_TARGET_TYPES: { TRANSACTIONS },
  STATUSES: { TO_REVIEW, NEED_REACTION }
} = DataConstants;

const CommentCell = (props) => {
  const {
    disabled,
    transaction,
    transactionState,
    localReasons,
    onChange,
    onReasonBlur
  } = props;

  const dispatch = useDispatch();

  const { id: transactionId, reason: originalReason, lastComment, comments, status } = transaction;

  const { reason, category = {} } = transactionState;

  const { uiTexts } = useSelector(getTextsData);

  const allUsersData = useSelector(getAllUsersData);

  const dropdownOpened = useSelector(getOpenedCommentsEntityId) === transactionId;

  const foundUser = lastComment ? Utils.arrayFindById(allUsersData, lastComment.createdBy) : null;

  const handleShowMoreClick = useCallback(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(transactionId));
  }, [dispatch, transactionId]);

  const handleCloseClick = useCallback(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(null));
  }, [dispatch]);

  const handleReasonChange = useCallback((value) => {
    onChange({ reason: value });
  }, [onChange]);

  return (
    <div className={Css.commentCell}>
      {status === TO_REVIEW
        ? (
          <AddCommentField
            id={transactionId}
            disabled={disabled}
            autoHeight={false}
            size={lastComment ? "sm" : undefined}
            compact={!!lastComment}
            className={Css.commentField}
            type={DataConstants.COMMENT_TARGET_TYPES.TRANSACTIONS} />
        )
        : (
          <ReasonInput
            size="sm"
            value={reason}
            disabled={disabled}
            validate={status === NEED_REACTION && (!category.code || !!originalReason)}
            multiline={!lastComment}
            localReasons={localReasons}
            placeholder={uiTexts.enterComment}
            onChange={handleReasonChange}
            onBlur={onReasonBlur} />
        )}
      {!!lastComment && (
        <>
          <DropDown opened={dropdownOpened}>
            <DropDownContent className={Css.dropdownContent}>
              <CommentsBlock
                compact popup
                commentTarget={TRANSACTIONS}
                commentTargetId={transactionId}
                comments={comments}
                onClose={handleCloseClick} />
            </DropDownContent>
          </DropDown>
          <div className={Css.lastComment}>
            <div className={Css.row}>
              <div className={Css.textShort}>{lastComment.text}</div>
              <div className={Css.textFull}>{lastComment.text}</div>
              <div className={Css.more} onClick={handleShowMoreClick}>
                {uiTexts.showMore}
              </div>
            </div>
            <AutoTooltip>
              <div className={Css.createdBy}>
                {foundUser
                  ? (foundUser.email + (foundUser.fullName === foundUser.email ? "" : ` (${foundUser.fullName})`))
                  : lastComment.createdByEmail}
              </div>
            </AutoTooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(CommentCell);
