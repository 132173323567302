import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const LastComment = ({ className, transactionId, lastComment }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [, setEnvVars] = useEnvVars();

  const handleCommentsClick = useCallback((event) => {
    if (!lastComment) return;

    event.stopPropagation();

    setEnvVars({ transactionComments: transactionId });
  }, [lastComment, transactionId, setEnvVars]);

  return (
    <div
      className={classNames(Css.lastComment, !lastComment && Css.noComment, className)}
      onClick={handleCommentsClick}>
      <Icons.ChatText className={Css.prepend} />
      <div className={Css.text}>
        {lastComment ? lastComment.text : uiTexts.noCommentsYet}
      </div>
      {!!lastComment && (
        <div className={Css.link}>
          <Icons.ArrowBendUpRight />
          <span>{uiTexts.reply}</span>
        </div>
      )}
    </div>
  );
};

export default React.memo(LastComment);
