
import Css from "./style.module.scss";

import { checkTransactionsFetching, getTransactionsStats } from "selectors/transactions";
import { getActiveOrganization } from "selectors/organizations";
import {
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Checkbox from "nlib/ui/Checkbox";
import Constants from "const/Constants";
import Countries from "const/Countries";
import Pagination from "nlib/ui/Pagination";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import SelectPageSize from "nlib/ui/SelectPageSize";
import Table, { TableHead, TableRow } from "nlib/ui/Table";
import TransactionsContext from "contexts/TransactionsContext";
import TransactionsTableRow from "./lib/TransactionsTableRow";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const BULK_VISIBLE_MIN_WIDTH = 900;

const INTERACTIVE_INPUTS_MIN_WIDTH = 882;

const EXPLANATION_MIN_WIDTH = 454;

const ACTIONS_MIN_WIDTH = 322;

const TransactionsTableBusiness = (props) => {
  const {
    disabled,
    localReasons,
    allItemsSelected,
    editableTransactionsIds,
    transactionsReadyToReview,
    availableWidth,
    selectedTransactions,
    onSelectedChange,
    onSelectAllChange
  } = props;

  const {
    transactionsData,
    transactionsState: tableItemsState,
    setTransactionsState: setTableItemsState,
    refetchTransactions,
    setLocalReasons
  } = useContext(TransactionsContext);

  const [envVars, setEnvVars] = useEnvVars();

  const { page = 1, pageSize = Constants.TABLE_PAGE_SIZE } = envVars;

  const { uiTexts } = useSelector(getTextsData);

  const transactionsStats = useSelector(getTransactionsStats);

  const transactionsFetching = useSelector(checkTransactionsFetching);

  const { countryCode } = useSelector(getActiveOrganization);

  const {
    settings: {
      allowClientPayeeSelection,
      allowClientClassSelection,
      allowClientLocationSelection,
      allowClientProjectSelection,
      allowClientCategorySelection,
      allowClientTaxRateSelection
    } = {}
  } = useSelector(getSelectedBusinessData);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const showBulkActions = availableWidth > BULK_VISIBLE_MIN_WIDTH;

  const showInteractiveInputsCell = availableWidth > INTERACTIVE_INPUTS_MIN_WIDTH;

  const showExplanationCell = availableWidth > EXPLANATION_MIN_WIDTH;

  const showActionsCell = availableWidth > ACTIONS_MIN_WIDTH;

  const usCountry = countryCode === Countries.US;

  const columnPayeeAndProjectEntities = useMemo(() => {
    return [
      (allowClientPayeeSelection) && { type: "address", label: uiTexts.payee },
      (allowClientProjectSelection) && !!projects.length && {
        type: "project",
        label: [
          projects.some(({ customer }) => !customer) && uiTexts.project,
          projects.some(({ customer }) => customer) && uiTexts.customer
        ].filter(Boolean).join("/")
      }
    ].filter(Boolean);
  }, [allowClientPayeeSelection, allowClientProjectSelection, projects, uiTexts]);

  const columnCategoryAndTaxRateEntities = useMemo(() => {
    return [
      allowClientCategorySelection && transactionsData.some(({ usesItems }) => !usesItems)
        && { type: "category", label: uiTexts.category },
      allowClientTaxRateSelection && !usCountry && !!taxRates.length
        && { type: "taxRate", label: uiTexts.taxRate }
    ].filter(Boolean);
  }, [allowClientCategorySelection, allowClientTaxRateSelection, taxRates.length, transactionsData, uiTexts, usCountry]);

  const columnClassAndLocationEntities = useMemo(() => {
    return [
      (allowClientClassSelection) && !!classes.length && { type: "class", label: uiTexts.class },
      (allowClientLocationSelection) && !!locations.length && { type: "location", label: uiTexts.location }
    ].filter(Boolean);
  }, [allowClientClassSelection, allowClientLocationSelection, classes.length, locations.length, uiTexts]);

  const handleRowChange = useCallback((id, newData) => {
    setTableItemsState((prevState) => {
      return Utils.arrayUpdateItemById(prevState, id, (prevItem) =>
        ({ ...prevItem, ...newData }));
    });
  }, [setTableItemsState]);

  const handleReasonBlur = useCallback(() => {
    const reasons = tableItemsState.map((item) => item.reason).filter(Boolean);

    setLocalReasons((prevReasons) => {
      return [...new Set([...reasons, ...prevReasons])];
    });
  }, [tableItemsState, setLocalReasons]);

  const handleTableSortChange = useCallback((sortParams) => {
    setEnvVars(sortParams);
  }, [setEnvVars]);

  const handlePageChange = useCallback((nextPage) => {
    setEnvVars({ page: nextPage });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((nextPageSize) => {
    setEnvVars({ pageSize: nextPageSize });
  }, [setEnvVars]);

  useEffect(() => {
    if (transactionsFetching
      || transactionsData.length
      || !transactionsStats.current) {
      return;
    }
    setEnvVars({ page: Math.ceil(transactionsStats.current / pageSize) });
  }, [
    page,
    pageSize,
    transactionsFetching,
    transactionsData.length,
    transactionsStats,
    setEnvVars
  ]);

  return (
    <div className={Css.tableContainer}>
      {!!transactionsData.length && (
        <Table
          disabled={disabled}
          className={Css.transactionsTable}
          sortBy={envVars.sortBy}
          sortOrder={envVars.sortOrder}
          onSortChange={handleTableSortChange}>
          <TableRow>
            <TableHead className={Css.checkboxCell} show={showBulkActions}>
              {!!transactionsData.length && (
                <Checkbox
                  disabled={!editableTransactionsIds.length}
                  checked={allItemsSelected}
                  indeterminate={!!selectedTransactions.length}
                  onChange={onSelectAllChange} />
              )}
            </TableHead>
            <TableHead className={Css.transactionCell} accessor="amount">
              {uiTexts.amount}
            </TableHead>
            <TableHead className={Css.dateCell} accessor="date">
              {uiTexts.transaction}
            </TableHead>
            <TableHead className={Css.commentCell} accessor="lastComment" show={showExplanationCell}>
              {uiTexts.explanation}
            </TableHead>
            <TableHead
              className={Css.payeeAndProjectCell}
              show={!!columnPayeeAndProjectEntities.length && showInteractiveInputsCell}
              accessor={columnPayeeAndProjectEntities.length === 1 ? columnPayeeAndProjectEntities[0].type : ""}>
              {columnPayeeAndProjectEntities.map(({ label }) => label).join(" / ")}
            </TableHead>
            <TableHead
              className={Css.categoryAndTaxRateCell}
              show={!!columnCategoryAndTaxRateEntities.length && showInteractiveInputsCell}
              accessor={columnCategoryAndTaxRateEntities.length === 1 ? columnCategoryAndTaxRateEntities[0].type : ""}>
              {columnCategoryAndTaxRateEntities.map(({ label }) => label).join(" / ")}
            </TableHead>
            <TableHead
              className={Css.classAndLocationCell}
              show={!!columnClassAndLocationEntities.length && showInteractiveInputsCell}
              accessor={columnClassAndLocationEntities.length === 1 ? columnClassAndLocationEntities[0].type : ""}>
              {columnClassAndLocationEntities.map(({ label }) => label).join(" / ")}
            </TableHead>
            <TableHead className={Css.documentCell}>{uiTexts.document}</TableHead>
            <TableHead className={Css.actionsCell} show={showActionsCell}>{uiTexts.actions}</TableHead>
          </TableRow>
          {transactionsData.map((transaction) => {
            return (
              <TransactionsTableRow
                disabled={disabled}
                key={transaction.id}
                selected={selectedTransactions.includes(transaction.id)}
                readyToReview={!!Utils.arrayFindById(transactionsReadyToReview, transaction.id)}
                showPayeeAndProjectsCell={!!columnPayeeAndProjectEntities.length && showInteractiveInputsCell}
                showCategoryAndTaxRateCell={!!columnCategoryAndTaxRateEntities.length && showInteractiveInputsCell}
                showClassAndLocationCell={!!columnClassAndLocationEntities.length && showInteractiveInputsCell}
                showBulkActions={showBulkActions}
                showExplanationCell={showExplanationCell}
                showActionsCell={showActionsCell}
                localReasons={localReasons}
                transaction={transaction}
                transactionState={Utils.arrayFindById(tableItemsState, transaction.id, {})}
                selectedTransactions={selectedTransactions}
                editableTransactionsIds={editableTransactionsIds}
                refetchTransactions={refetchTransactions}
                onChange={handleRowChange}
                onReasonBlur={handleReasonBlur}
                onSelectedChange={onSelectedChange} />
            );
          })}
        </Table>
      )}
      <div className={Css.footer}>
        {(transactionsStats.current > Number(pageSize)) && (
          <Pagination
            className={Css.pagination}
            count={transactionsStats.current}
            page={Number(page)}
            pageSize={Number(pageSize)}
            disabled={disabled}
            onChange={handlePageChange} />
        )}
        {(transactionsStats.current > Constants.TABLE_PAGE_SIZE) && (
          <SelectPageSize
            className={Css.pageSize}
            disabled={disabled}
            pageSize={Number(pageSize)}
            onChange={handlePageSizeChange} />
        )}
      </div>
    </div>
  );
};

export default React.memo(TransactionsTableBusiness);
