
import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import { checkTransactionsFetching, getTransactionsStats } from "selectors/transactions";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AmountCell from "nlib/common/TransactionsTable/lib/AmountCell";
import Checkbox from "nlib/ui/Checkbox";
import Constants from "const/Constants";
import ContactsActions from "actions/ContactsActions";
import DataConstants from "const/DataConstants";
import ExtraCell from "nlib/common/TransactionsTable/lib/ExtraCell";
import Pagination from "nlib/ui/Pagination";
import ProgressCell from "nlib/common/TransactionsTable/lib/ProgressCell";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import SelectPageSize from "nlib/ui/SelectPageSize";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import TransactionCell from "nlib/common/TransactionsTable/lib/TransactionCell";
import TransactionsContext from "contexts/TransactionsContext";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const TransactionsTable = (props) => {
  const {
    disabled,
    localReasons,
    allItemsSelected,
    editableTransactionsIds,
    transactionsReadyToProcess,
    transactionsReadyToApprove,
    closedBookDateTransactionIds,
    simplifyLayout,
    selectedTransactions,
    onReasonBlur,
    onSelectedChange,
    onSelectAllChange
  } = props;

  const {
    transactionsData,
    transactionsState: tableItemsState,
    setTransactionsState: setTableItemsState,
    refetchTransactions
  } = useContext(TransactionsContext);

  const dispatch = useDispatch();

  const [envVars, setEnvVars] = useEnvVars();

  const { editItem, page = 1, pageSize = Constants.TABLE_PAGE_SIZE } = envVars;

  const [editItemId] = editItem ? editItem.split(".") : [];

  const { uiTexts } = useSelector(getTextsData);

  const transactionsStats = useSelector(getTransactionsStats);

  const transactionsFetching = useSelector(checkTransactionsFetching);

  const [validatedVendors, setValidatedVendors] = useState([]);

  const fetchVendorsValidationStats = useCallback(async(vendorIds = [], mergeResults = false, sparseUpdate = false) => {
    if (!Array.isArray(vendorIds)) vendorIds = [vendorIds];
    if (vendorIds.length) {
      await dispatch(ContactsActions.fetchVendorsValidationStats(vendorIds, mergeResults, sparseUpdate));
    }
  }, [dispatch]);

  const handleTransactionChange = useCallback((id, update) => {
    setTableItemsState((prevState) => {
      return Utils.arrayUpdateItemById(prevState, id, (state) => {
        return { ...state, ...update };
      });
    });
  }, [setTableItemsState]);

  const handleTableSortChange = useCallback((sortParams) => {
    setEnvVars(sortParams);
  }, [setEnvVars]);

  const handlePageChange = useCallback((nextPage) => {
    setEnvVars({ page: nextPage });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((nextPageSize) => {
    setEnvVars({ pageSize: nextPageSize });
  }, [setEnvVars]);

  const handleSelectedChange = useCallback((value, event) => {
    onSelectedChange(event.target.dataset.id, value);
  }, [onSelectedChange]);

  useEffect(() => {
    if (transactionsFetching
      || transactionsData.length
      || !transactionsStats.current) {
      return;
    }
    setEnvVars({ page: Math.ceil(transactionsStats.current / pageSize) });
  }, [
    page,
    pageSize,
    transactionsFetching,
    transactionsData.length,
    transactionsStats,
    setEnvVars
  ]);

  useLayoutEffect(() => {
    const vendorIds = [...new Set(tableItemsState.map(({ vendorId }) => vendorId).filter(Boolean))];

    const unvalidatedVendors = vendorIds.filter((vendorId) => vendorId && !validatedVendors.includes(vendorId));

    if (unvalidatedVendors.length) {
      setValidatedVendors([...validatedVendors, ...unvalidatedVendors]);
      fetchVendorsValidationStats(unvalidatedVendors, !!validatedVendors.length);
    }
  }, [validatedVendors, tableItemsState, fetchVendorsValidationStats]);

  return (
    <div className={Css.tableContainer}>
      {!!transactionsData.length && (
        <Table
          disabled={disabled}
          className={Css.transactionsTable}
          sortBy={envVars.sortBy}
          sortOrder={envVars.sortOrder}
          onSortChange={handleTableSortChange}>
          <TableRow className={Css.tableRowHeader}>
            <TableHead className={Css.checkboxCell}>
              {!!transactionsData.length && (
                <Checkbox
                  disabled={!editableTransactionsIds.length}
                  checked={allItemsSelected}
                  indeterminate={!!selectedTransactions.length}
                  onChange={onSelectAllChange} />
              )}
            </TableHead>
            <TableHead className={Css.transactionCell} accessor="transaction">
              {uiTexts.transaction}
            </TableHead>
            <TableHead className={Css.amountCell} accessor="amount">
              {uiTexts.amount}
            </TableHead>
            <TableHead className={Css.placeholderCell} />
            <TableHead className={Css.extraCell} />
            {envVars.status === DataConstants.STATUSES.IN_PROGRESS && (
              <TableHead className={Css.progressCell}>
                {uiTexts.progress}
              </TableHead>
            )}
          </TableRow>
          {transactionsData.map((transaction) => {
            const { id: transactionId, status, roboticAiStatus, lastExportErrors } = transaction;

            const transactionState = Utils.arrayFindById(tableItemsState, transactionId, {});

            const readyToProcess = !!Utils.arrayFindById(transactionsReadyToProcess, transactionId);

            const readyToApprove = !!Utils.arrayFindById(transactionsReadyToApprove, transactionId);

            const toReviewStatus = status === DataConstants.STATUSES.TO_REVIEW;

            const closedBookDate = closedBookDateTransactionIds.includes(transactionId);

            return (
              <TableRow
                disabled={disabled}
                key={transactionId}
                className={classNames(
                  CommonCss.tableRow,
                  (transactionId === editItemId || selectedTransactions.includes(transactionId))
                    && CommonCss.selectedRow,
                  [
                    toReviewStatus && Array.isArray(lastExportErrors) && !!lastExportErrors.length && CommonCss.negativeRow,
                    (readyToApprove || readyToProcess || toReviewStatus) && closedBookDate && CommonCss.warningRow,
                    readyToApprove && CommonCss.positiveRow,
                    readyToProcess && CommonCss.highlightRow
                  ].find(Boolean)
                )}>
                <TableCell className={Css.checkboxCell}>
                  <Checkbox
                    disabled={!editableTransactionsIds.includes(transactionId)}
                    checked={selectedTransactions.includes(transactionId)}
                    data-id={transactionId}
                    onChange={handleSelectedChange} />
                </TableCell>
                <TableCell className={Css.transactionCell}>
                  <TransactionCell transaction={transaction} />
                </TableCell>
                <TableCell className={Css.amountCell}>
                  <AmountCell transaction={transaction} />
                </TableCell>
                <TableCell className={Css.placeholderCell} />
                <TableCell className={Css.extraCell}>
                  <ExtraCell
                    disabled={disabled}
                    simplifyLayout={simplifyLayout}
                    readyToProcess={readyToProcess}
                    readyToApprove={readyToApprove}
                    inProgress={envVars.status === DataConstants.STATUSES.IN_PROGRESS}
                    localReasons={localReasons}
                    selected={selectedTransactions.includes(transactionId)}
                    selectable={editableTransactionsIds.includes(transactionId)}
                    transaction={transaction}
                    transactionState={transactionState}
                    refetchTableData={refetchTransactions}
                    onChange={handleTransactionChange}
                    onSelectedChange={onSelectedChange}
                    onReasonBlur={onReasonBlur} />
                </TableCell>
                {envVars.status === DataConstants.STATUSES.IN_PROGRESS && (
                  <TableCell className={Css.progressCell}>
                    <ProgressCell transactionId={transactionId} roboticAiStatus={roboticAiStatus} />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </Table>
      )}
      <div className={Css.footer}>
        {(transactionsStats.current > Number(pageSize)) && (
          <Pagination
            disabled={disabled}
            className={Css.pagination}
            count={transactionsStats.current}
            page={Number(page)}
            pageSize={Number(pageSize)}
            onChange={handlePageChange} />
        )}
        {(transactionsStats.current > Constants.TABLE_PAGE_SIZE) && (
          <SelectPageSize
            disabled={disabled}
            className={Css.pageSize}
            pageSize={Number(pageSize)}
            onChange={handlePageSizeChange} />
        )}
      </div>
    </div>
  );
};

export default React.memo(TransactionsTable);
