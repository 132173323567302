import * as Icons from "@phosphor-icons/react";
import {
  checkSelectedBusinessHasWhoAsk,
  checkSelectedBusinessRpaMode
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useSelector } from "react-redux";
import NoDataContent from "nlib/common/NoDataContent";
import React from "react";
import TransactionsStatuses from "nlib/pages/TransactionsPage/TransactionsStatuses";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const EmptyState = ({ fetchingData }) => {
  const [{ status: statusFilter }] = useEnvVars();

  const { messages, uiTexts } = useSelector(getTextsData);

  const userRole = useSelector(getUserRole);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const selectedBusinessHasWhoAsk = useSelector(checkSelectedBusinessHasWhoAsk);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const { labelLangId: statusLabelLangId } = (statusFilter && TransactionsStatuses.getStatusData([
    statusFilter,
    businessOrganization,
    rpaMode && !selectedBusinessHasWhoAsk,
    businessUser
  ])) || {};

  const statusLabel = statusLabelLangId && uiTexts[statusLabelLangId].toLowerCase();

  if (fetchingData) return null;

  return (
    <NoDataContent
      icon={!statusLabel && businessUser ? Icons.CheckCircle : Icons.CreditCard}
      title={statusLabel
        ? Utils.replaceTextVars(uiTexts.noStatusTransactions, { status: statusLabel })
        : (businessUser ? messages.allThingsDone : uiTexts.noTransactions)} />
  );
};

export default React.memo(EmptyState);
